import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {FaChevronLeft} from 'react-icons/fa';

import './styles.css';
import {getUserServices} from '../../services/api';

const expandedButtonsStyle = {
    color: '#e1173f',
    borderColor: '#e1173f',
    backgroundColor: '#ffffff'
};

const unexpandedButtonsStyle = {
    color: '#ffffff',
    borderColor: '#ffffff',
    backgroundColor: '#e1173f'
};

const firstExpandedRowStyles = {
    height: '231px',
    maxHeight: '231px',
};

const firstUnexpandedRowStyles = {
    height: '137px',
};

const expandedRowStyles = {
    height: '231px',
    maxHeight: '231px',
};

const unexpandedRowStyles = {
    height: '137px',
};

const firstMobileExpandedRowStyles = {
    height: '231px',
    marginBottom: '116px',
};

const firstMobileUnexpandedRowStyles = {
    height: 'auto',
};

const expandedMobileRowStyles = {
    height: '231px',
    maxHeight: '231px',
    marginTop: '25px',
    marginBottom: '116px',
};

const unexpandedMobileRowStyles = {
    height: 'auto',
};

export default function Services(props) {
    const history = useHistory();

    const token = localStorage.getItem('client_token');

    const [services, setServices] = useState([]);

    useEffect(() => {
        getUserServices()
            .then(
                response => {
                    console.log(response.data);
                    setServices(response.data.filter(service => {
                        service.expanded = false;
                        return service;
                    }));
                }
            )
            .catch(error => {
                props.errorHandler(error);
            });
    }, []);

    const handleButtonClick = (serviceId) => {
        setServices(services.filter(service => {
            service.expanded = service.id === serviceId ? !service.expanded : false;
            return service;
        }));
    };

    const handleFixInfos = (service) => {
        localStorage.setItem('payment', 'success');
        localStorage.setItem('update', service.id);
        history.push('/data-titulo');
    };

    return (
        <div className={'content-container services-container'} style={{width: 'auto'}}>
            <section>
                <h3>
                    {'Meu parcelamento'}
                </h3>
                <span className={'sub-title'}>
                    {'Você solicitou parcelamento de dívida do seu Boleto DAS.'}
                    <br/>
                    <br/>
                    {'Segue o retorno abaixo de nossa equipe'}
                </span>

                <table className={'custom-table desktop-table'}>
                    {(services && services.length > 0) && (
                        services.map((service, key) => (
                            <div key={service.id} style={
                                key === 0 ?
                                    service.expanded ? firstExpandedRowStyles : firstUnexpandedRowStyles :
                                    service.expanded ? expandedRowStyles : unexpandedRowStyles
                            }>
                                <tr>
                                    <td>
                                        {/*DAS {service.year}*/}
                                        {service.id}
                                    </td>
                                    <td>
                                        Data de solicitação:
                                        <br />
                                        {new Intl.DateTimeFormat('pt-BR').format(new Date(service.solicitado))}
                                    </td>
                                    <td>
                                Status
                                        <br />
                                        {
                                            (service.status === '1')
                                                ? 'Pendente' :
                                                (service.status === '2')
                                                    ? 'Em andamento' :
                                                    (service.status === '3')
                                                        ? 'Aguardando correção' :
                                                        (service.status === '4')
                                                            ? 'Finalizado' :
                                                            (service.status === '5')
                                                                ? 'Corrigido' :
                                                                (service.status === '6')
                                                                    ? 'Cancelado' : 'Erro'
                                        }
                                    </td>
                                    <td>
                                        <button
                                            style={service.expanded ? expandedButtonsStyle : unexpandedButtonsStyle}
                                            onClick={() => handleButtonClick(service.id)}
                                        >
                                            {(service.expanded ? '- ' : '+ ')}
                                            {'Detalhes'}
                                        </button>
                                    </td>
                                </tr>
                                {(service.expanded) &&
                                (<div className={'expanded-content'}>
                                    <hr />
                                    <span>
                                        {service.status === '1' && 'Este parcelamento ainda está pendente'}
                                        <br />
                                        {service.status !== '1' && service.status !== '3' && service.historico[0].descricao}
                                        {service.status !== '1' && service.historico[0].observacao}
                                        {(service.status === '3') && (
                                            <button
                                                onClick={() => handleFixInfos(service)}
                                            >
                                                {'Corrigir informação'}
                                            </button>
                                        )}
                                    </span>
                                </div>)}
                            </div>
                        ))
                    )}
                </table>
                <table className={'custom-table mobile-table'}>
                    {(services && services.length > 0) && (
                        services.map((service, key) => (
                            <div key={service.id} style={
                                key === 0 ?
                                    service.expanded ? firstMobileExpandedRowStyles : firstMobileUnexpandedRowStyles :
                                    service.expanded ? expandedMobileRowStyles : unexpandedMobileRowStyles
                            }>
                                <tr>
                                    <td>
                                        Parcelamento
                                        <br />
                                        {'Data: '}{new Intl.DateTimeFormat('pt-BR').format(new Date(service.solicitado))}
                                        <br />
                                        {'Status: '}
                                        {
                                            (service.status === '1')
                                                ? 'Pendente' :
                                                (service.status === '2')
                                                    ? 'Em andamento' :
                                                    (service.status === '3')
                                                        ? 'Aguardando correção' :
                                                        (service.status === '4')
                                                            ? 'Finalizado' :
                                                            (service.status === '5')
                                                                ? 'Corrigido' :
                                                                (service.status === '6')
                                                                    ? 'Cancelado' : 'Erro'
                                        }
                                        <br />
                                        <br />
                                        <button
                                            style={service.expanded ? expandedButtonsStyle : unexpandedButtonsStyle}
                                            onClick={() => handleButtonClick(service.id)}
                                        >
                                            {(service.expanded ? '- ' : '+ ')}
                                            {'Detalhes'}
                                        </button>
                                    </td>
                                </tr>
                                {(service.expanded) &&
                                (<div className={'expanded-content'}>
                                    <hr />
                                    <span>
                                        {service.status === '1' && 'Este parcelamento ainda está pendente'}
                                        <br />
                                        {service.status !== '1' && service.status !== '3' && service.historico[0].descricao}
                                        {service.status !== '1' && service.historico[0].observacao}
                                        {(service.status === '3') && (
                                            <button
                                                onClick={() => handleFixInfos(service)}
                                            >
                                                {'Corrigir informação'}
                                            </button>
                                        )}
                                    </span>
                                </div>)}
                            </div>
                        ))
                    )}
                </table>

                <div className={'footer-buttons'}>
                    <FaChevronLeft className={'back-icon'} onClick={() => history.push(`/?token=${token.split(' ')[1]}`)}/>
                    <a className={'back-text'} onClick={(e) => history.push(`/?token=${token.split(' ')[1]}`)}>
                        Voltar
                    </a>
                </div>
            </section>
        </div>
    );
}

import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

import App from './App';

ReactGA.initialize('UA-112487562-1', {
    gaOptions: {
        cookieFlags: 'SameSite=None;Secure'
    }
});

Sentry.init({dsn: 'https://bba5e9c280524750a50a6f1f9f2bee61@o377285.ingest.sentry.io/5266176'});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();

import axios from 'axios';

const api = axios.create({
    baseURL: 'https://www.dicasmei.com.br/api/' // PROD
    // baseURL: 'https://dev.dicasmei.com.br/api/' // DEV
});

const getToken = (type) => {
    return type === 1 ?
        localStorage.getItem('api_token') : localStorage.getItem('client_token');
};

export function getApiToken() {
    return api.post('login_check', {
        'username': 'api_user',
        'password': 'pZ25ShU2DRo3gxYf'
    }, {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    });
}

export function getUserData() {
    return api.get('cliente/mei', {
        headers: {
            'accept': 'application/json',
            'authorization': getToken(2)
        }
    });
}

export function sendUserPaymentData(data) {
    return api.post('cliente/dadospagamento', data, {
        headers: {
            'accept': 'application/json',
            'authorization': getToken(2)
        }
    });
}

export function sendPaymentData(data) {
    return api.post('cliente/pagamento/unico/5', data, {
        headers: {
            'accept': 'application/json',
            'authorization': getToken(2)
        }
    });
}

export function requestService() {
    return api.post('cliente/servico/parcelamentodas', {
        datnascimento: localStorage.getItem('birthDate'),
        destitulo_eleitor: localStorage.getItem('title'),
        desir: localStorage.getItem('simpleNational'),
        desrg: localStorage.getItem('cpf')
    }, {
        headers: {
            'accept': 'application/json',
            'authorization': getToken(2)
        }
    });
}

export function fixService() {
    return api.path('cliente/servico/parcelamentodas', {
        coddemanda: localStorage.getItem('update'),
        datnascimento: localStorage.getItem('birthDate'),
        destitulo_eleitor: localStorage.getItem('title'),
        desir: localStorage.getItem('simpleNational'),
        desrg: localStorage.getItem('cpf')
    }, {
        headers: {
            'accept': 'application/json',
            'authorization': getToken(2)
        }
    });
}

export function getUserServices() {
    return api.get('cliente/servico/demandas', {
        headers: {
            'accept': 'application/json',
            'authorization': getToken(2)
        }
    });
}

export default api;

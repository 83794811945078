import React from 'react';

export default function TermsOfUse() {

    return (
        <div style={{overflowX: 'hidden', overflowY: 'scroll', maxHeight: '480px'}}>
            TERMOS E CONDIÇÕES DE USO
            DicasMEI
            <br/>
            Última atualização: 17 de julho de 2019
            <br/>
            1.	Introdução	1
            2.	Definições	1
            3.	Relação Contratual	2
            4.	Serviços	3
            5.	Utilização dos Serviços pelo Usuário	3
            6.	Privacidade, coleta e uso de dados	4
            7.	Propriedade intelectual	4
            8.	Exceções de garantias	5
            9.	Exceções de responsabilidade	6
            10.	Rescisão contratual	7
            11.	Disposições gerais	7
            12.	Fale com a DicasMEI	8
            <br/>
            <br/>
            Introdução
            <br/>
            Obrigado por escolher a DicasMEI!
            <br/>
            Os presentes Termos e Condições de Uso regem os Serviços disponibilizados pela DicasMEI ao Usuário.
            <br/>
            O USUÁRIO DEVERÁ LER CUIDADOSAMENTE ESTES TERMOS E CONDIÇÕES ANTES DE ACESSAR OU UTILIZAR OS SERVIÇOS.
            <br/>
            O acesso e a utilização dos Serviços pelo Usuário estão sujeitos a estes Termos e Condições de Uso, os quais deverão ser lidos e aceitos pelo Usuário antes de acessar e utilizar os Serviços.
            <br/>
            <br/>
            Definições
            <br/>
            Para os fins destes Termos e Condições de Uso, as seguintes expressões terão o seguinte significado:
            <br/>
            &quot;DicasMEI&quot;: DICAS MEI ASSESSORIA EMPRESARIAL LTDA., pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 29.162.060/0001-92, com sede na Rua Jorge Nasralla, nº 1-18, Vila Guedes de Azevedo, CEP 17012-330, no município de Bauru/SP;
            <br/>
            &quot;Conta&quot;: conta do Usuário dos Serviços;
            <br/>
            &quot;Conteúdo de Usuário&quot;: conteúdo ou informação em formato de texto, áudio ou visual, incluindo comentários e reações relacionados aos Serviços;
            <br/>
            “Dados do Usuário”: todas as informações referentes ao Usuário, o que pode incluir informações comerciais, estratégicas, sigilosas e Dados Pessoais do próprio Usuário (quando pessoa física) ou de seu representante (quando pessoa jurídica);
            <br/>
            “Dados Pessoais”: toda e qualquer informação relacionada a uma pessoa identificada ou identificável;
            <br/>
            &quot;Plataforma&quot;: sítio eletrônico “https://www.dicasmei.com.br/” e aplicativo “DicasMEI”, disponível nas plataformas Android e iOS, além de qualquer outro meio disponibilizado pela DicasMEI como parte dos Serviços.
            <br/>
            &quot;Serviços&quot;: conteúdo, produtos e serviços da Plataforma, disponibilizados pela DicasMEI, para acesso ou uso pelo Usuário;
            <br/>
            &quot;Termos&quot;: os presentes Termos e Condições de Uso;
            <br/>
            &quot;Usuário&quot;: pessoa física ou jurídica, que acesse ou use os Serviços.
            <br/>
            <br/>
            Relação Contratual
            <br/>
            Ao acessar e usar os Serviços, o Usuário concorda com os Termos, que estabelecem o relacionamento contratual entre ele e a DicasMEI. Se o Usuário não concorda com os Termos, não poderá acessar nem usar os Serviços.
            <br/>
            Mediante referido acesso e uso, os presentes Termos imediatamente encerram, substituem e superam todos os acordos, Termos e acertos anteriores entre o Usuário e a DicasMEI.
            <br/>
            Termos adicionais poderão se aplicar a determinados Serviços, os quais serão divulgados em relação aos respectivos Serviços. Eles serão considerados complementares e parte integrante destes Termos para os efeitos dos respectivos Serviços. Caso sejam percebidas disposições conflitantes entre o presente Termo e os adicionais, as disposições dos Termos adicionais deverão prevalecer.
            <br/>
            A DicasMEI poderá alterar os Termos relativos aos Serviços a qualquer momento. Aditamentos entrarão em vigor quando a DicasMEI publicar a versão atualizada dos Termos neste local ou das condições atualizadas ou Termos adicionais sobre o respectivo Serviço. O Usuário será notificado quando os Termos forem alterados, e caso não concorde com eles, poderá solicitar o cancelamento da sua Conta, pelos canais de atendimento mencionados no item 12. O fato de o Usuário continuar a acessar ou usar os Serviços após essa postagem e notificação representa seu consentimento em vincular-se aos Termos alterados.
            <br/>
            A coleta e o tratamento de dados pessoais associados aos Serviços está disciplinada na Política de Privacidade da DicasMEI, disponibilizada para o Usuário no sítio eletrônico:.
            <br/>
            <br/>
            Serviços
            <br/>
            O Usuário, ao acessar a Plataforma e utilizar os Serviços, poderá ter acesso a um serviço de consultoria fiscal e tributária específica para todos aqueles que se enquadram na categoria de Microempreendedor Individual (“MEI”), conforme funcionalidades disponibilizadas na Plataforma. Os Serviços são operados na Plataforma online, podendo o Usuário formalizar o seu cadastro na categoria do MEI, solicitar boletos DAS (tributo competente do MEI), alterar seus dados cadastrais, cancelar seu cadastro e ainda obter informações sobre a categoria e disponibilização de benefícios, bem como demais dicas sobre o assunto. Todos os serviços estão disponíveis no item “Serviços” da Plataforma e poderão ser livremente solicitados por você.
            <br/>
            O Usuário está ciente e autoriza expressamente a emissão automática de documentos fiscais em seu nome quando mantenha vínculo com Empresa Parceira da DicasMEI, conforme seja indicado pelo próprio Usuário ou pela Empresa Parceira.
            <br/>
            O acesso à Plataforma pode ser feito pelo aplicativo “DicasMEI” para Android e iOS (“Aplicativo”), disponível nas respectivas Lojas de Aplicativos, ou por meio do sítio eletrônico “https://www.dicasmei.com.br/” (“Plataforma Web”). O uso de tais portais de conteúdo, ainda que apenas para obter o Aplicativo da Plataforma, está sujeito às regras próprias dos seus respectivos fornecedores.
            <br/>
            <br/>
            Utilização dos Serviços pelo Usuário
            <br/>
            Condutas e obrigações do Usuário. Para utilizar os Serviços, o Usuário tem de efetuar um registro e manter uma Conta. O Usuário deve ter pelo menos 18 anos de idade para acessar e utilizar os Serviços. O registro para obtenção de uma Conta exige o fornecimento de determinados dados pessoais à DicasMEI, tais como o nome, e-mail, telefone, número do CPF (Cadastro de Pessoa Física) e do CNPJ (Cadastro Nacional de Pessoa Jurídica).
            <br/>
            O Usuário concorda em manter as informações referentes a sua Conta corretas, completas e atualizadas, caso contrário o Usuário poderá ficar impossibilitado de acessar e usar os Serviços. O Usuário é responsável pela segurança e confidencialidade do nome de usuário e da senha da sua Conta.
            <br/>
            Salvo autorização por escrito da DicasMEI, o Usuário só pode ter uma Conta associada ao seu respectivo número do Cadastro de Pessoa Física (CPF) e do Cadastro Nacional da Pessoa Jurídica (CNPJ).
            <br/>
            O Usuário não pode autorizar terceiros a acessarem ou utilizarem a sua Conta. O Usuário não pode ceder ou por outra forma transferir a sua Conta para qualquer outra pessoa física ou jurídica. O Usuário aceita cumprir todas as leis aplicáveis durante a utilização dos Serviços, e só pode utilizar os Serviços para fins legítimos. O Usuário, durante a utilização dos Serviços, não praticará qualquer conduta ilegal.
            <br/>
            Condutas proibidas. O Usuário fica proibido, sob pena de suspensão ou cancelamento da Conta, sem prejuízo das penalidades legais, de: (i) utilizar a Plataforma para finalidades ilícitas; (ii) submeter à Plataforma, falsamente, dados de terceiros; (iii) submeter à Plataforma dados protegidos por direitos autorais dos quais não possa dispor; (iv) submeter à Plataforma qualquer tipo de conteúdo ilegal, imoral ou incompatível com o Serviço, tais como: material preconceituoso, racista, ofensivo, injurioso, difamatório, calunioso, pornográfico, relacionado a menores de idade em qualquer aspecto e em especial pornografia ou exploração infantil, além de qualquer informação, ferramenta, software ou conhecimento que permita a um usuário ou terceiro violar, de qualquer forma, os presentes Termos. Igual tratamento será concedido a qualquer tipo de arquivo, sítio eletrônico, mídia ou informação inserida de forma criptografada, protegida por senha ou de outro modo não imediatamente identificado como material ilícito.
            <br/>
            Integridade da Plataforma e violações aos Termos. O Usuário fica proibido, igualmente e sob as mesmas penas de todas Condutas proibidas, de: (i) modificar, apagar, sabotar ou de qualquer forma violar, ou auxiliar, incentivar ou facilitar a terceiro a violação, da Plataforma, seja o sítio eletrônico, o Aplicativo ou o Serviço conectado subjacente; (ii) violar conteúdo protegido por direitos de propriedade intelectual da DicasMEI ou de qualquer afiliado, subcontratado ou parceiro; (iii) não buscar, abordar ou aceitar abordagem de terceiro para, de qualquer forma utilizar o Serviço ou qualquer de seus componentes para fraudar os Termos ou violar a Lei.
            <br/>
            <br/>
            Privacidade, coleta e uso de dados
            <br/>
            Privacidade. Ao utilizar a Plataforma, o Usuário insere diversos dados, os quais podem incluir dados pessoais. A DicasMEI está comprometida em garantir a confidencialidade e a segurança das suas informações, por isso elaboramos a Política de Privacidade disponibilizada para o Usuário no endereço eletrônico.
            <br/>
            Parte dos Termos. A Política de Privacidade da plataforma é parte integrantes destes Termos, devendo com eles ser interpretada e aplicada, prevalecendo, contudo, naquilo que for específica à matéria de tratamento e proteção de dados pessoais.
            <br/>
            <br/>
            Propriedade intelectual
            <br/>
            Propriedade intelectual da DicasMEI. A DicasMEI é titular exclusiva de todos os direitos, morais e patrimoniais, incidentes ou eventualmente incidentes sobre aspectos visuais e/ou artísticos da Plataforma, das interfaces do sítio eletrônico e do Aplicativo, bem como o código-fonte, os elementos técnicos, de design, de processos, relatórios, e outros que nos ajudam a caracterizar a Plataforma, a aplicação em si, todos os sinais distintivos, marcas, logos e qualquer material passível de proteção por direitos autorais ou propriedade industrial. Para permitir sua utilização da Plataforma, a DicasMEI concede, pela duração da manutenção da sua Conta no Serviço, uma licença de uso, limitada, revogável, intransferível, não exclusiva e não passível de sublicenciamento, dos direitos de propriedade intelectual necessários à utilização do Serviço, o que não inclui direitos de reprodução, alteração, comunicação ao público ou qualquer forma de distribuição. É estritamente proibida qualquer tipo de cópia, alteração ou redistribuição do aplicativo, do sítio eletrônico, do código-fonte ou de qualquer elemento relativo à Propriedade Intelectual, em parte ou no todo. O Usuário também concorda que não vai fazer, tentar fazer, ou ajudar alguém a fazer nenhum tipo de engenharia reversa ou tentativa de acesso ao código fonte e estrutura do banco de dados, em relação à Plataforma.
            <br/>
            Violação de propriedade intelectual de terceiros. O Usuário é responsável pelas informações inseridas na Plataforma, e declara que tem o direito ou as necessárias licenças para utilizar-se de tal material. Na eventualidade de tal presunção provar-se falsa, mediante descoberta fortuita ou notificação do interessado, o conteúdo em questão será imediatamente removido da Plataforma, sendo notificado o Usuário responsável sobre a remoção, exceto no caso de determinação judicial em contrário.
            <br/>
            Direito de regresso. A DicasMEI não se responsabiliza pela violação, por parte do Usuário, de direitos de propriedade intelectual de terceiro, sendo o Usuário responsável pela violação obrigado a intervir nos procedimentos em trâmite de modo a isentar a DicasMEI de qualquer resultado negativo. Ainda que tais esforços não sejam suficientes, a DicasMEI terá direito integral de regresso contra o Usuário, quando o dano a ser indenizado decorra direta ou indiretamente de culpa deste. A DicasMEI declara que não realiza e não está obrigada a realizar controle prévio das informações submetidas à Plataforma.
            <br/>
            Notificações sobre violações. Quaisquer notificações sobre violações de propriedade intelectual podem ser encaminhadas para o correio eletrônico.
            <br/>
            <br/>
            Exceções de garantias
            <br/>
            Os Serviços são prestados &quot;como são&quot; e &quot;nos termos em que se encontram disponíveis&quot;. A DicasMEI não aceita quaisquer declarações e garantias, expressas, implícitas ou legais, não expressamente estabelecidas nestes Termos.
            <br/>
            A DicasMEI não faz qualquer declaração e nem presta qualquer garantia relativamente à confiabilidade, tempestividade, qualidade, adequação ou disponibilidade dos Serviços ou de que os Serviços serão isentos de interrupções ou de erros.
            <br/>
            Acesso à rede e dispositivos. O Usuário é responsável por seus dados para obter o acesso a rede da DicasMEI. As taxas e encargos de sua rede de dados poderão se aplicar se você acessar ou usar os Serviços de um dispositivo sem fio e você será responsável por essas taxas e encargos. O Usuário é responsável por adquirir e atualizar os dispositivos necessários para acessar e usar os Serviços e quaisquer de suas atualizações.
            <br/>
            A DicasMEI não garante que os Serviços, ou qualquer parte deles, funcionarão em qualquer equipamento ou dispositivo em particular. Além disso, os Serviços poderão estar sujeitos a mau funcionamento e atrasos inerentes ao uso da Internet e de comunicações eletrônicas.
            <br/>
            Ação de agentes maliciosos. O Usuário está ciente de que, apesar de toda a segurança que nos esforçarmos em implementar, não há garantia contra a ação de hackers, crackers, ciberativistas e outros agentes maliciosos que podem tentar ou desejem violar a Plataforma por diversão ou para ganho próprio ou de terceiros, e concorda que, nestes casos, não haverá qualquer responsabilidade da DicasMEI pelos danos daí decorrentes.
            <br/>
            <br/>
            Exceções de responsabilidade
            <br/>
            Danos pelo uso do Serviço. A DicasMEI, suas afiliadas, subcontratantes e parceiros não são responsáveis por quaisquer danos, sejam eles de natureza física, moral, estética, econômica, acidental, punitiva, perda de chance, lucros cessantes, perda de dados, ou qualquer outra que você ou terceiro venha a experimentar direta ou indiretamente relacionados ao uso dos Serviços. O Usuário também reconhece que a DicasMEI não é responsável por qualquer tipo de danos oriundos de, mas não limitado a: (i) vulnerabilidades de segurança nos dispositivos de acesso à Plataforma, (ii) danos causados por ações ou omissões do Usuário; (iii) utilização indevida dos dispositivos; (iv) interrupções ou erros na transmissão de dados pela internet ou telefonia celular; (v) intervenção de qualquer espécie do Poder Público ou ainda (vi) força maior ou caso fortuito. A Plataforma é ofertada tal como se encontra e o uso feito dos Serviços é de responsabilidade do próprio Usuário.
            <br/>
            Danos sofridos por interferência de terceiros. A DicasMEI, suas afiliadas, subcontratantes e parceiros não são responsáveis por quaisquer danos, sejam eles de natureza física, moral, estética, econômica, acidental, punitiva, perda de chance, lucros cessantes, perda de dados, ou qualquer outra que você ou terceiro venham a experimentar em razão da interferência ilegítima de terceiros nos Serviços prestados.
            <br/>
            Direito de regresso. Caso a DicasMEI seja implicada de qualquer maneira em cobranças extrajudiciais ou qualquer outra medida judicial em decorrência de danos causados por um Usuário ou pessoas pelas quais é responsável o Usuário, este se obriga a intervir nos procedimentos em trâmite, de modo a isentar a DicasMEI de qualquer possível resultado negativo. Ainda que tais esforços não sejam suficientes, a DicasMEI terá direito integral de regresso contra seus usuários quando o dano a ser indenizado decorra direta ou indiretamente de culpa de um Usuário.
            <br/>
            Acesso a logs. A DicasMEI não tem a obrigação de fornecer e não fornecerá eventuais logs ou registros de conexão e de acesso à Plataforma que não digam respeito ao próprio Usuário solicitante, exceto se assim determinado por decisão judicial.
            <br/>
            <br/>
            Rescisão contratual
            <br/>
            Os Termos, em regra, estão vigentes desde o registro realizado pelo Usuário até seu desligamento da Plataforma, seja ele realizado a pedido de uma das partes, por comum acordo ou por descontinuidade de qualquer um dos Serviços.
            <br/>
            Rescisão por violação contratual ou legal. Caso o Usuário viole qualquer disposição dos Termos, da Política de Privacidade ou da Lei vigente, a DicasMEI fica autorizada, à sua discrição, a suspender ou cancelar a Conta do infrator.
            <br/>
            Rescisão a pedido do usuário. Caso o Usuário solicite o encerramento da sua Conta, sua conta será excluída no prazo de 30 (trinta) dias a contar do pedido, feito por meio da própria Plataforma. Após este prazo, todas as informações referentes à Conta serão apagadas definitivamente, exceto quando tais dados forem coletados ou tratados sobre outras bases legais ou quando a DicasMEI esteja obrigada, por Lei, a armazenar determinados dados.
            <br/>
            <br/>
            Disposições gerais
            <br/>
            Lei aplicável. Estes Termos são regidos pela lei brasileira, sendo esta integralmente aplicável a quaisquer disputas que possam surgir sobre a interpretação ou execução deste contrato, assim como qualquer outra disputa que envolva direta ou indiretamente a prestação dos Serviços.
            <br/>
            Tolerância. Eventuais omissões ou meras tolerâncias das partes no exigir o estrito e pleno cumprimento dos Termos ou de prerrogativas decorrentes dele ou de lei, não constituirão novação ou renúncia, nem afetarão o exercício de quaisquer direitos, que poderão ser plena e integralmente exercidos, a qualquer tempo.
            <br/>
            Independência das cláusulas. Caso se perceba que uma disposição é nula, as disposições restantes dos Termos permanecerão em pleno vigor e um termo válido substituirá o termo nulo, refletindo nossa intenção, tanto quanto possível.
            <br/>
            Eleição de foro. As partes optam desde já por reconhecer a competência da Justiça Estadual brasileira, elegendo o foro da Comarca de Bauru/SP, Brasil, e renunciando a qualquer outro, por mais privilegiado que seja.
            <br/>
            <br/>
            Fale com a DicasMEI
            <br/>
            Obrigado por ler nossos Termos. Se tiver quaisquer dúvidas relativamente a estes Termos, entre em contato através do formulário &quot;Fale Conosco&quot; no endereço eletrônico https://www.dicasmei.com.br/faleconosco ou escrevendo para o seguinte endereço eletrônico contato@dicasmei.com.br.
            <br/>
            © DicasMEI
        </div>
    );
}

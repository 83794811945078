import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    FaFrown
} from 'react-icons/fa';

import './styles.css';

export default function AuthGuard() {
    return (
        <div className={'auth-error-content'}>
            <Helmet>
                <title>
                    DicasMei :: You Shall Not Pass!
                </title>
            </Helmet>
            <FaFrown
                size={300}
                width={200}
                color={'#ff3f4e'}
                className={'sad-face-icon'}
            />
            <h3 className={'sad-text'}>
                {'Ops! Parece que sua sessão expirou, mas não se preocupe, basta recarregar a página para voltar a usar nosso sistema novamente.'}
            </h3>
        </div>
    );
}

import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { SkyLightStateless } from 'react-skylight';
import ProgressButton from 'react-progress-button';
import {
    FaChevronLeft
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import Terms from './term';

import './styles.css';

export default function CnpjInput() {
    const history = useHistory();

    const [cnpj, setCnpj] = useState(localStorage.getItem('cnpj') ? localStorage.getItem('cnpj') : '');
    const [termAgreement, setTermAgreement] = useState(localStorage.getItem('acceptedTerm') === 'yes');
    const [buttonState, setButtonState] = useState('');

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showTermsModal, setShowTermsModal] = useState(false);

    const termsModalStyle = {
        padding: '30px',
        backgroundColor: '#f0f0f5',
        color: '#000',
        width: '70%',
        height: '630px',
        marginTop: '-300px',
        marginLeft: '-35%',
        borderRadius: '8px',
    };

    function validateCnpj(_cnpj) {

        _cnpj = _cnpj.replace(/[^\d]+/g, '');

        if (_cnpj === '') return false;

        if (_cnpj.length !== 14)
            return false;


        if (_cnpj === '00000000000000' ||
            _cnpj === '11111111111111' ||
            _cnpj === '22222222222222' ||
            _cnpj === '33333333333333' ||
            _cnpj === '44444444444444' ||
            _cnpj === '55555555555555' ||
            _cnpj === '66666666666666' ||
            _cnpj === '77777777777777' ||
            _cnpj === '88888888888888' ||
            _cnpj === '99999999999999')
            return false;


        var tamanho = _cnpj.length - 2;
        var numeros = _cnpj.substring(0, tamanho);
        var digitos = _cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)) return false;
        tamanho = tamanho + 1;
        numeros = _cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return false;

        return true;

    }

    async function handleCheckTerm() {
        setTermAgreement(!termAgreement);
        if (!termAgreement === true) {
            setHasError(false);
            setShowTermsModal(false);
        }
    }

    function handleGoBack(e) {
        e.preventDefault();

        history.goBack();
    }

    function handleSubmit(e) {
        e.preventDefault();
        setHasError(false);
        setButtonState('loading');
        if (!validateCnpj(cnpj)) {
            setHasError(true);
            setErrorMessage('Parece que o CNPJ digitado não é válido. Por favor, verifique-o e tente novamente.');
            setTimeout(() => {
                setButtonState('error');
            }, 500);
        }
        else if (!termAgreement) {
            setHasError(true);
            setErrorMessage('Você precisa aceitar os termos de uso para poder continuar.');
            setTimeout(() => {
                setButtonState('error');
            }, 200);
        }
        else {
            setTimeout(() => {
                localStorage.setItem('cnpj', cnpj);
                localStorage.setItem('acceptedTerm', 'yes');
                setButtonState('success');
                history.push('/dados-pessoais');
            }, 800);
        }
    }

    return (
        <div className={'content-container'}>
            <section>
                <h3>
                    Tem dívida de boletos DAS? Parcele e fique em dia!
                </h3>
                <p>
                    Por apenas
                    <span>
                        &nbsp;
                        R$xxx,xx
                        &nbsp;
                    </span>
                    nós fazemos a parte burocrática para você.
                    O parcelamento segue toda a regra do governo.  Caso queira saber mais, clique aqui.
                </p>
                <form onSubmit={handleSubmit}>
                    <div className={'label-content'}>
                        <InputMask
                            required
                            id={'cnpj'}
                            className={'cnpj-input'}
                            mask={'99.999.999/9999-99'}
                            value={cnpj}
                            onChange={e => setCnpj(e.target.value)}
                        />
                        <label
                            htmlFor={'cnpj'}
                            className={'input-label'}
                        >
                            CNPJ
                        </label>
                    </div>
                    {(hasError) && (
                        <span className={'error-message'}>
                            {errorMessage}
                        </span>
                    )}
                    <div style={{margin: 'auto'}}>
                        <input
                            className={'checkbox'}
                            checked={termAgreement}
                            type={'checkbox'}
                            onChange={(e) => handleCheckTerm()}
                        />
                        <label
                            className={'checkbox-label'}
                            onClick={(e) => e.preventDefault()}
                        >
                            Li e aceito os &nbsp;
                            <span onClick={() => setShowTermsModal(true)}>
                            termos de uso
                            </span>
                        </label>
                    </div>
                    <div className={'footer-buttons'}>
                        <FaChevronLeft className={'back-icon'} onClick={(e) => handleGoBack(e)}/>
                        <a className={'back-text'} onClick={(e) => handleGoBack(e)}>
                            Voltar
                        </a>
                        <ProgressButton
                            state={buttonState}
                        >
                            Iniciar Agora
                        </ProgressButton>
                    </div>
                </form>
            </section>
            <SkyLightStateless
                hideOnOverlayClicked
                title={<h1 style={{marginBottom: '20px'}}>Termos de Uso</h1>}
                isVisible={showTermsModal}
                dialogStyles={termsModalStyle}
                onCloseClicked={() => setShowTermsModal(false)}
            >
                <Terms />
                <div className={'checkbox-container'}>
                    <input
                        checked={termAgreement}
                        type={'checkbox'}
                        onChange={() => handleCheckTerm()}
                    />
                    <label onClick={(e) => handleCheckTerm()}>
                        Li e aceito os termos de uso
                    </label>
                </div>
            </SkyLightStateless>
        </div>
    );
}

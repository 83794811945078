import React, { useState } from 'react';
import MaskedInput from 'react-input-mask';
import ProgressButton from 'react-progress-button';
import { useHistory } from 'react-router-dom';
import {
    FaChevronLeft
} from 'react-icons/fa';

import './styles.css';
import {fixService, requestService} from '../../services/api';

export default function SimpleNational(props) {
    const history = useHistory();

    const [buttonState, setButtonState] = useState('');
    const [ir, setIr] =
        useState(localStorage.getItem('ir') ?
            localStorage.getItem('ir') : '');
    const [year, setYear] =
        useState(localStorage.getItem('year') ?
            localStorage.getItem('year') : '');
    const [simpleError, setSimplerError] = useState(false);

    function handleSimpleChange(value) {
        setSimplerError(false);
        setIr(value);
        document.getElementsByName('ir')[0].style.borderColor = '';
    }

    function handleSubmit(e) {
        e.preventDefault();
        setButtonState('loading');

        const simpleElement = document.getElementsByName('ir')[0];

        simpleElement.style.borderColor = '';

        if (!ir || ir === '') {
            setTimeout(() => {
                setSimplerError(true);
                simpleElement.style.borderColor = 'red';
                setButtonState('error');
            }, 600);
        }
        else {
            localStorage.setItem('ir', ir);
            setTimeout(() => {
                setButtonState('success');
                history.push('/analise');
            }, 600);
            if (localStorage.getItem('update')) {
                fixService()
                    .then(response => {
                        setTimeout(() => {
                            setButtonState('success');
                            localStorage.removeItem('update');
                            localStorage.removeItem('payment');
                            history.push('/analise');
                        }, 600);
                    })
                    .catch(error => {
                        props.errorHandler(error);
                        setTimeout(() => {
                            setButtonState('error');
                        }, 600);
                    });
            }
            else {
                requestService()
                    .then(response => {
                        setTimeout(() => {
                            setButtonState('success');
                            history.push('/analise');
                        }, 600);
                    })
                    .catch(error => {
                        props.errorHandler(error);
                        setTimeout(() => {
                            setButtonState('error');
                        }, 600);
                    });
            }
        }
    }

    return (
        <div className={'content-container ir-content'} style={{width: 'auto'}}>
            <section>
                <h3>
                    {/*<FaChevronLeft className={'back-icon-title'} onClick={() => history.goBack()}/>*/}
                    Declaração de imposto de renda
                </h3>
                <p>
                    Insira os 10 primeiros números do seu recibo de entrega do imposto de renda.
                    Deve ser da última declaração. Não esqueça do ano da declaração.
                </p>
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                    <div className={'label-content'}>
                        <input
                            required
                            id={'ir'}
                            name={'ir'}
                            value={ir}
                            className={'input-spacer'}
                            onChange={(e) => handleSimpleChange(e.target.value)}
                        />
                        <label
                            id={'simpleNationalLabel'}
                            htmlFor={'ir'}
                            className={'input-label'}
                        >
                            Número do recibo
                        </label>
                    </div>
                    <div className={'label-content'}>
                        <input
                            required
                            id={'year'}
                            name={'year'}
                            value={year}
                            className={'input-spacer'}
                            onChange={(e) => setYear(e.target.value)}
                        />
                        <label
                            id={'yearLabel'}
                            htmlFor={'year'}
                            className={'input-label'}
                        >
                            Ano
                        </label>
                    </div>
                    {simpleError && (
                        <span className={'error-message'}>
                            {'O imposto de renda não pode ser vazio.' +
                            ' Por favor, informe-o para prosseguirmos.'}
                        </span>
                    )}
                    <div className={'footer-buttons'}>
                        <FaChevronLeft className={'back-icon'} onClick={() => history.goBack()}/>
                        <a className={'back-text'} onClick={(e) => history.goBack()}>
                            Voltar
                        </a>
                        <ProgressButton
                            state={buttonState}
                        >
                            Próximo Passo
                        </ProgressButton>
                    </div>
                </form>
            </section>
        </div>
    );
}

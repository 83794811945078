import React, {useEffect} from 'react';
import {
    Route,
    Switch,
    BrowserRouter
} from 'react-router-dom';
import {captureException} from '@sentry/browser';

import Menu from './Pages/Menu';
import CnpjInput from './Pages/CnpjInput';
import PersonalData from './Pages/PersonalData';
import TitleAndBirth from './Pages/TitleAndBirth';
import SimpleNational from './Pages/SimpleNationalCode';
import Analysis from './Pages/Analysis';
import Services from './Pages/MyServices';
import AuthGuard from './Pages/AuthGuard';
import {useHistory} from 'react-router-dom';

export default function Routes() {
    const history = useHistory();

    const errorHandler = (error) => {
        if (error && error.response && error.response.status !== 401) {
            captureException(error);
        } else {
            history.push('/401');
        }
    };

    return (
        <Switch>
            <Route path={'/'} exact>
                <Menu
                    errorHandler={errorHandler}
                />
            </Route>
            <Route path={'/cnpj'} exact>
                <CnpjInput
                    errorHandler={errorHandler}
                />
            </Route>
            <Route path={'/dados-pessoais'} exact>
                <PersonalData
                    errorHandler={errorHandler}
                />
            </Route>
            <Route path={'/data-titulo'} exact >
                <TitleAndBirth
                    errorHandler={errorHandler}
                />
            </Route>
            <Route path={'/simplesnacional'} exact >
                <SimpleNational
                    errorHandler={errorHandler}
                />
            </Route>
            <Route path={'/analise'} exact >
                <Analysis
                    errorHandler={errorHandler}
                />
            </Route>
            <Route path={'/meus-parcelamentos'} exact>
                <Services
                    errorHandler={errorHandler}
                />
            </Route>
            <Route path={'/401'} component={AuthGuard} exact/>
        </Switch>
    );
}

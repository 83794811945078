import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {
    FaChevronLeft
} from 'react-icons/fa';
import ReactGA from 'react-ga';

import './styles.css';

export default function Analysis() {
    const history = useHistory();

    const token = localStorage.getItem('client_token');

    const [bill, setBill] = useState(
        localStorage.getItem('link_boleto') ? localStorage.getItem('link_boleto') : null);

    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'Final Screen',
            label: (bill) ? 'Boleto' : 'Cartão',
            nonInteraction: true
        });
        localStorage.removeItem('payment');
    }, []);

    return (
        <div className={'content-container content-container-analysis'}>
            <section>
                <h3>
                    Seu parcelamento será analisado!
                </h3>
                <span className={'analysis-info'}>
                    {'Em até 3 dias úteis você receberá por email o valor mínimo de parcela e o primeiro boleto. ' +
                    'Os boletos restantes serão encaminhados todo dia 10 de cada mês.'}
                </span>
                <div className={'analysis-box'}>
                    <span className={'analysis-box-info'}>
                        {'É importante não perder o prazo desses pagamentos. '}
                        <br/>
                        {'Caso perca, será necessário iniciar um novo processo de parcelamento junto ao governo.  '}
                    </span>
                </div>
                {(bill) && (
                    <div className={'bill-warn'}>
                        <span className={'bill-info'}>
                            {'Sua opção de pagamento escolhida foi boleto bancário, o processo de parcelamento só dará continuidade após recebermos o seu pagamento (que pode levar até 3 dias úteis).'}
                            <br />
                            {'Segue abaixo o seu boleto'}
                        </span>
                    </div>
                )}
                <div className={'footer-buttons'} style={{width: '410px'}}>
                    <FaChevronLeft className={'back-icon'} onClick={() => history.push(`/?token=${token.split(' ')[1]}`)}/>
                    <a className={'back-text'} onClick={(e) => history.push(`/?token=${token.split(' ')[1]}`)}>
                        Voltar ao início
                    </a>
                    {(bill) && (
                        <a className={'bill-link'} href={bill} target={'_blank'} rel='noopener noreferrer'>
                            Visualizar boleto
                        </a>
                    )}
                </div>
            </section>
        </div>
    );
}

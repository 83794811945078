import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import MaskedInput from 'react-input-mask';
import ProgressButton from 'react-progress-button';
import {
    FaChevronLeft
} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './styles.css';
import {sendPaymentData, sendUserPaymentData} from '../../services/api';

export default function PersonalData(props)  {
    const history = useHistory();

    const [buttonState, setButtonState] = useState('');
    const [cepStatus, setCepStatus] = useState('');
    const [name, setName] = useState(localStorage.getItem('name') ? localStorage.getItem('name') : '');
    const [cpf, setCpf] = useState(localStorage.getItem('cpf') ? localStorage.getItem('cpf') : '');
    const [rg, setRg] = useState(localStorage.getItem('rg') ? localStorage.getItem('rg') : '');
    const [cpfError, setCpfError] = useState(false);
    const [phone, setPhone] = useState(localStorage.getItem('phone') ? localStorage.getItem('phone') : '');
    const [cep, setCep] = useState(localStorage.getItem('cep') ? localStorage.getItem('cep') : '');
    const [states, setStates] = useState([]);
    const [state, setState] = useState(localStorage.getItem('state') ? localStorage.getItem('state') : '');
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(localStorage.getItem('city') ? localStorage.getItem('city') : '');
    const [address, setAddress] = useState(localStorage.getItem('address') ? localStorage.getItem('address') : '');
    const [district, setDistrict] = useState(localStorage.getItem('district') ? localStorage.getItem('district') : '');
    const [number, setNumber] = useState(localStorage.getItem('number') ? localStorage.getItem('number') : '');

    useEffect(() => {
        const hasCep = localStorage.getItem('cep') ? localStorage.getItem('cep') : '';
        if (hasCep !== '' && cep.match(/\d/g).join('').length === 8) {
            fetchCep(cep.match(/\d/g).join(''));
        }
    }, []);

    function fetchCep(cepNumber) {
        setCepStatus('loading');
        Axios.get(`https://viacep.com.br/ws/${cepNumber}/json`)
            .then(
                response => {
                    if (response.data.erro === true) {
                        setCepStatus('error');
                    } else {
                        setState(response.data.uf);
                        setCity(response.data.localidade);
                        setDistrict(response.data.bairro);
                        setAddress(response.data.logradouro);
                        setCepStatus('success');
                    }
                }
            )
            .catch(
                error => setCepStatus('error')
            );
    }

    function testCpf(strCPF) {
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF === '00000000000') return false;
        if (strCPF === '11111111111') return false;
        if (strCPF === '22222222222') return false;
        if (strCPF === '33333333333') return false;
        if (strCPF === '44444444444') return false;
        if (strCPF === '55555555555') return false;
        if (strCPF === '66666666666') return false;
        if (strCPF === '77777777777') return false;
        if (strCPF === '88888888888') return false;
        if (strCPF === '99999999999') return false;

        for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11))  Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10)) ) return false;

        Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11))  Resto = 0;
        return Resto === parseInt(strCPF.substring(10, 11));

    }

    function handleCpfChange(value) {
        setCpf(value);
        if (value && value !== '' && value.match(/(\d)/g) && value.match(/(\d)/g).join('')
            && value.match(/(\d)/g).join('').length >= 11) {
            setCpfError(!testCpf(value.match(/(\d)/g).join('')));
            document.getElementsByName('cpf')[0]
                .style.borderColor = testCpf(value.match(/(\d)/g).join('')) ? 'green' : 'red';
        }
    }

    async function handleCepChange(cep) {
        setCep(cep);
        if (cep !== '' && cep.match(/\d/g) && cep.match(/\d/g).join('').length === 8) {
            await fetchCep(cep.match(/\d/g).join(''));
        }
    }

    function handleGoBack(e) {
        e.preventDefault();

        history.goBack();
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setTimeout(() => {
            setButtonState('loading');
        }, 400);

        const inputElements = [
            document.getElementsByName('rg')[0],
            document.getElementsByName('cpf')[0],
            document.getElementsByName('cep')[0],
            document.getElementsByName('phone')[0],
            document.getElementsByName('state')[0],
            document.getElementsByName('city')[0],
            document.getElementsByName('district')[0],
            document.getElementsByName('address')[0],
            document.getElementsByName('number')[0],
        ];

        inputElements.map(element => {
            if (element !== undefined) element.style.borderColor = '';
        });

        if (cpf && cpf !== '' && cpf.match(/(\d)/g) && cpf.match(/(\d)/g).join('')
            && cpf.match(/(\d)/g).join('').length >= 11) {
            await setCpfError(!testCpf(cpf.match(/(\d)/g).join('')));
        } else {
            await setCpfError(true);
        }

        if (cpf === '' || cpfError) {
            document.getElementsByName('cpf')[0].style.borderColor = 'red';
        }
        else if (cepStatus !== 'success') {
            document.getElementsByName('cep')[0].style.borderColor = 'red';
        }
        else if (rg === '') {
            document.getElementsByName('rg')[0].style.borderColor = 'red';
        }
        else if (phone === '') {
            document.getElementsByName('phone')[0].style.borderColor = 'red';
        }
        else if (name === '') {
            document.getElementsByName('name')[0].style.borderColor = 'red';
        }
        else if (state === '') {
            document.getElementsByName('state')[0].style.borderColor = 'red';
        }
        else if (city === '') {
            document.getElementsByName('city')[0].style.borderColor = 'red';
        }
        else if (district === '') {
            document.getElementsByName('district')[0].style.borderColor = 'red';
        }
        else if (address === '') {
            document.getElementsByName('address')[0].style.borderColor = 'red';
        }
        else if (number === '') {
            document.getElementsByName('number')[0].style.borderColor = 'red';
        }
        else {
            localStorage.setItem('rg', rg);
            localStorage.setItem('cpf', cpf);
            localStorage.setItem('name', name);
            localStorage.setItem('phone', phone);
            localStorage.setItem('cep', cep);
            localStorage.setItem('state', state);
            localStorage.setItem('city', city);
            localStorage.setItem('district', district);
            localStorage.setItem('address', address);
            localStorage.setItem('number', number);
            await sendUserPaymentData({
                cpf: cpf.match(/\d/g).join(''),
                celular: phone,
                cep: cep.match(/\d/g).join(''),
                nome: name,
                estado: state,
                cidade: city,
                bairro: district,
                endereco: address,
                numero: number
            })
                .then(() => {
                    processPayment();
                })
                .catch(error => {
                    props.errorHandler(error);
                    setTimeout(() => {
                        setButtonState('error');
                    }, 400);
                });
            return true;
        }
        setTimeout(() => {
            setButtonState('error');
        }, 400);
    }

    function processPayment() {
        setButtonState('loading');
        if (localStorage.getItem('payment') !== 'success') {
            new window.PagarMeCheckout.Checkout({
                encryption_key: 'ek_live_Sn25Xudzdvbp6HPFwCOftHQMwoTD7d', // LIVE
                // encryption_key: 'ek_test_32NmzUnMn4IrQtH7FaDJEgqcvAnDTa', // TEST
                success: function(data) {
                    sendPaymentData(data)
                        .then(response => {
                            if (response.data.link_boleto) {
                                localStorage.setItem('link_boleto', response.data.link_boleto);
                            }
                            localStorage.setItem('payment', 'success');
                            setTimeout(() => {
                                setButtonState('success');
                                history.push('/data-titulo');
                            }, 800);
                        })
                        .catch(error => {
                            props.errorHandler(error);
                            setTimeout(() => {
                                setButtonState('error');
                            }, 400);
                        });
                    localStorage.setItem('payment', 'success');
                    setTimeout(() => {
                        setButtonState('success');
                        history.push('/data-titulo');
                    }, 800);
                },
                error: function(error) {
                    props.errorHandler(error, 'payment');
                    setTimeout(() => {
                        setButtonState('error');
                    }, 400);
                },
                close: function() {
                    setTimeout(() => {
                        setButtonState('error');
                        confirmAlert({
                            title: 'Atenção',
                            message: 'É necessário efetuar o pagamento para poder continuar',
                            buttons: [
                                {
                                    label: 'Ok'
                                }
                            ]
                        });
                    }, 800);
                }
            }).open({
                maxInstallments: 1,
                amount: 2200,
                buttonText: 'Pagar',
                customerData: 'false',
                createToken: 'false',
                paymentMethods: 'credit_card, boleto'
            });
        }
        else {
            setTimeout(() => {
                setButtonState('success');
                history.push('/data-titulo');
            }, 800);
        }
    }

    return (
        <div className={'content-container'}>
            <section>
                <h3>
                    Dados Pessoais
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className={'label-content'}>
                        <input
                            required
                            id={'name'}
                            name={'name'}
                            value={name}
                            className={'input-spacer'}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <label
                            htmlFor={'name'}
                            className={'input-label'}
                        >
                            NOME
                        </label>
                    </div>
                    <div className={'label-content'}>
                        <MaskedInput
                            required
                            id={'cpf'}
                            name={'cpf'}
                            value={cpf}
                            mask={'999.999.999-99'}
                            className={'input-spacer'}
                            onChange={(e) => handleCpfChange(e.target.value)}
                        />
                        <label
                            htmlFor={'cpf'}
                            className={'input-label'}
                        >
                            CPF
                        </label>
                    </div>
                    <div className={'label-content'}>
                        <input
                            required
                            id={'rg'}
                            name={'rg'}
                            value={rg}
                            className={'input-spacer'}
                            onChange={(e) => setRg(e.target.value)}
                        />
                        <label
                            htmlFor={'cpf'}
                            className={'input-label'}
                        >
                            RG
                        </label>
                    </div>
                    {(cpfError) && (
                        <span className={'error-message'}>
                            {'CPF inválido'}
                        </span>
                    )}
                    <div className={'label-content'}>
                        <MaskedInput
                            required
                            id={'phone'}
                            name={'phone'}
                            value={phone}
                            mask={'(99) 99999-9999'}
                            className={'input-spacer'}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <label
                            htmlFor={'phone'}
                            className={'input-label'}
                        >
                            CELULAR
                        </label>
                    </div>
                    <div className={'label-content'}>
                        <MaskedInput
                            required
                            id={'cep'}
                            name={'cep'}
                            value={cep}
                            mask={'99999-999'}
                            className={'input-spacer'}
                            onChange={(e) => handleCepChange(e.target.value)}
                        />
                        <label
                            htmlFor={'cep'}
                            className={'input-label'}
                        >
                            CEP
                        </label>
                    </div>
                    {(cepStatus === 'loading') && (
                        <div className={'address-container'}>
                            <h4 className={'loading-status'}>Carregando...</h4>
                        </div>
                    )}
                    {(cepStatus === 'error') && (
                        <span className={'error-message'}>
                            {'Desculpe, não encontramos dados para o CEP digitado. ' +
                            'Por favor, verifique os números e tente novamente.'}
                        </span>
                    )}
                    {(cepStatus === 'success') && (
                        <div className={'address-container'}>
                            <div className={'label-content'}>
                                <MaskedInput
                                    id={'state'}
                                    name={'state'}
                                    value={state}
                                    className={'input-spacer'}
                                    onChange={(e) => setState(e.target.value)}
                                />
                                <label
                                    htmlFor={'state'}
                                    className={'input-label'}
                                >
                                    ESTADO
                                </label>
                            </div>
                            <div className={'label-content'}>
                                <MaskedInput
                                    required
                                    id={'city'}
                                    name={'city'}
                                    value={city}
                                    className={'input-spacer'}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                                <label
                                    htmlFor={'city'}
                                    className={'input-label'}
                                >
                                    CIDADE
                                </label>
                            </div>
                            <div className={'label-content'}>
                                <MaskedInput
                                    required
                                    id={'address'}
                                    name={'address'}
                                    value={address}
                                    className={'input-spacer'}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <label
                                    htmlFor={'address'}
                                    className={'input-label'}
                                >
                                    ENDEREÇO
                                </label>
                            </div>
                            <div className={'label-content'}>
                                <MaskedInput
                                    required
                                    id={'district'}
                                    name={'district'}
                                    value={district}
                                    className={'input-spacer'}
                                    onChange={(e) => setDistrict(e.target.value)}
                                />
                                <label
                                    htmlFor={'district'}
                                    className={'input-label'}
                                >
                                    BAIRRO
                                </label>
                            </div>
                            <div className={'label-content'}>
                                <input
                                    required
                                    autoFocus
                                    id={'number'}
                                    name={'number'}
                                    value={number}
                                    className={'input-spacer'}
                                    onChange={(e) => setNumber(e.target.value)}
                                />
                                <label
                                    htmlFor={'number'}
                                    className={'input-label'}
                                >
                                    NÚMERO
                                </label>
                            </div>
                        </div>
                    )}
                    <div className={'footer-buttons'}>
                        <FaChevronLeft className={'back-icon'} onClick={(e) => handleGoBack(e)}/>
                        <a className={'back-text'} onClick={(e) => handleGoBack(e)}>
                            Voltar
                        </a>
                        <ProgressButton
                            controlled
                            state={buttonState}
                        >
                            Próximo Passo
                        </ProgressButton>
                    </div>
                </form>
            </section>
        </div>
    );
}

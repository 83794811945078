import React from 'react';
import iconSecurity from './icon-security.svg';
import iconSecurityWhite from './icon-security-white.svg';
import './styles.css';

export default function Security({ color }) {

    function getParagraphClass() {
        return color === 'white'
            ? 'paragraph paragraph_small paragraph_white bold'
            : 'paragraph paragraph_small bold';
    }

    function getIcon() {
        return color === 'white'
            ? iconSecurityWhite
            : iconSecurity;
    }

    return (
        <div className="security">
            <div className="security__text">
                <p className={getParagraphClass()}>Ambiente seguro</p>
                <p className={getParagraphClass()}>Seus dados protegidos</p>
            </div>
            <div className="security__icon">
                <img src={getIcon()} alt="Ícone segurança" />
            </div>
        </div>
    );
}

import React from 'react';
import {Helmet} from 'react-helmet';

import Routes from './routes';
import Header from './Header';

import './global.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import {BrowserRouter} from 'react-router-dom';

function App() {
    return (
        <div className="App">
            <Helmet>
                <title>
                    DicasMei :: Parcelamento
                </title>
            </Helmet>
            <Header />
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </div>
    );
}

export default App;

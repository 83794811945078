import React, {useState} from 'react';
import ProgressButton from 'react-progress-button';
import MaskedInput from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import {
    FaChevronLeft
} from 'react-icons/fa';

export default function TitleAndBirth() {
    const history = useHistory();

    const [buttonState, setButtonState] = useState('');
    const [birthDate, setBirthDate] =
        useState(localStorage.getItem('birthDate') ? localStorage.getItem('birthDate') : '');
    const [birthError, setBirthError] = useState(false);

    const [title, setTitle] =
        useState(localStorage.getItem('title') ? localStorage.getItem('title') : '');
    const [titleError, setTitleError] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();
        const elementBirth = document.getElementsByName('birth')[0];
        const elementTitle = document.getElementsByName('title')[0];

        elementBirth.style.borderColor = '';
        elementTitle.style.borderColor = '';

        setBirthError(false);
        setTitleError(false);
        setButtonState('loading');

        if (!birthDate ||
            birthDate === '' ||
            (birthDate.match(/(\d)/g) &&
                birthDate.match(/(\d)/g).join('') &&
                birthDate.match(/(\d)/g).join('').length < 8
            )
        ) {
            setTimeout(() => {
                setButtonState('error');
                setBirthError(true);
                elementBirth.style.borderColor = 'red';
            }, 600);
        }
        else if (!title || title === '' || title.length < 12) {
            setTimeout(() => {
                setButtonState('error');
                setTitleError(true);
                elementTitle.style.borderColor = 'red';
            }, 600);
        }
        else {
            setTimeout(() => {
                setButtonState('success');
                localStorage.setItem('title', title);
                localStorage.setItem('birthDate', birthDate);
                history.push('/simplesnacional');
            }, 800);
        }
    }

    return (
        <div className={'content-container'}>
            <section>
                <h3>
                    <FaChevronLeft className={'back-icon-title'} onClick={() => history.goBack()}/>
                    Título e data de nascimento
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className={'label-content'}>
                        <MaskedInput
                            required
                            id={'birth'}
                            name={'birth'}
                            value={birthDate}
                            mask={'99/99/9999'}
                            className={'input-spacer'}
                            onChange={(e) => setBirthDate(e.target.value)}
                        />
                        <label
                            id={'birthLabel'}
                            htmlFor={'birth'}
                            className={'input-label'}
                        >
                            Data de nascimento
                        </label>
                    </div>
                    {birthError && (
                        <span className={'error-message'}>
                            {'Ops, parece que a data digitada é inválida.' +
                            'Por favor, verifique os números e tente novamente.'}
                        </span>
                    )}
                    <div className={'label-content'}>
                        <input
                            required
                            id={'title'}
                            name={'title'}
                            value={title}
                            className={'input-spacer'}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <label
                            htmlFor={'title'}
                            className={'input-label'}
                        >
                            Título de eleitor
                        </label>
                    </div>
                    {(titleError) && (
                        <span className={'error-message'}>
                            {'O Título de eleitor é inválido. ' +
                            'Por favor, verifique os números e tente novamente.'}
                        </span>
                    )}
                    <div className={'footer-buttons'}>
                        <FaChevronLeft className={'back-icon'} onClick={() => history.goBack()}/>
                        <a className={'back-text'} onClick={(e) => history.goBack()}>
                            Voltar
                        </a>
                        <ProgressButton
                            state={buttonState}
                        >
                            Próximo Passo
                        </ProgressButton>
                    </div>
                </form>
            </section>
        </div>
    );
}

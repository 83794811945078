import './style.css';
import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';
import {useHistory,useLocation} from 'react-router-dom';
import {
    getApiToken,
    getUserData,
} from '../../services/api';

import Menu1 from './imgs/menu-1.png';
import Menu2 from './imgs/menu-2.png';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Menu = (props) => {

    const query = useQuery();
    const token = query.get('token');

    const loadUserTokens = () => {
        getApiToken()
            .then(response => {
                localStorage.setItem('client_token', `Bearer ${token}`);
                localStorage.setItem('api_token', `Bearer ${response.data.token}`);
                if (token === null) {
                    setLoadingUserData(false);
                    const errorObject = {
                        response: {
                            status: 401
                        }
                    };
                    props.errorHandler(errorObject);
                }
                else {
                    getUserData()
                        .then(response => {
                            localStorage.setItem('cnpj', response.data.cnpj);
                            setLoadingUserData(false);
                        })
                        .catch(error => {
                            if (error.response.data !== 404) props.errorHandler(error);
                            setLoadingUserData(false);
                        });
                }
            })
            .catch(error => {
                props.errorHandler(error);
            });
    };

    const history = useHistory();

    const [loadingUserData, setLoadingUserData] = useState(true);

    useEffect(() => {
        localStorage.removeItem('update');
        setLoadingUserData(true);
        loadUserTokens();
    }, []);

    return (
        <div className="menu">
            <span className={'page-title'}>Parcelamento do DAS</span>
            <span className={'page-description'}>
                    Tem dívida de boletos DAS? Parcele e fique em dia!
            </span>

            {(loadingUserData) ? (
                <div className={'loading-data'}>
                    <Loader
                        width={125}
                        height={125}
                        color='#e1173f'
                        type="TailSpin"
                    />
                    <h3 className={'loader-text'}>Carregando seus dados...</h3>
                </div>
            ) : (
                <div className="menu-cards">
                    <div className="card" onClick={() => {
                        history.push('/cnpj');
                    }}>
                        <img className="menu-image" src={Menu1}/>
                        <div className={'card-description'}>
                            <span className="card-title">Solicitar parcelamento</span>
                            <span className="card-sub">Parcele seus boletos DAS e fique em dia</span>
                            <button className={'card-button'}>
                                Solicitar parcelamento
                            </button>
                        </div>
                    </div>
                    <div className="card" onClick={() => history.push('/meus-parcelamentos')}>
                        <img className="menu-image" src={Menu2}/>
                        <div className={'card-description'}>
                            <span className="card-title">Meus parcelametos</span>
                            <span className="card-sub">Lista completa do seu historico de parcelamentos</span>
                            <button className={'card-button'}>
                                Ver meus parcelamentos
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Menu;

